/* MarkerPopup.css */
.marker-popup {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    font-family: 'Arial', sans-serif;
    color: #333;
}

.marker-popup .header {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.marker-popup .redcat-id,
.marker-popup .bookmark {
    background-color: #4A90E2;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 6px;
    transition: background-color 0.3s;
}

.marker-popup .redcat-id:hover,
.marker-popup .bookmark:hover {
    background-color: #357ABD;
}

.marker-popup .redcat-id {
    flex-grow: 1;
    font-weight: bold;
    font-size: 14px;
}

.marker-popup .bookmark {
    cursor: pointer;
}

.marker-popup .price-difference,
.marker-popup .not-analyzed {
    background-color: #F0F4F8;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 12px;
    font-weight: bold;
}

.marker-popup .price-bar {
    position: relative;
    height: 8px;
    background: linear-gradient(90deg, #FF6B6B 0%, #FFD93D 50%, #6BCB77 100%);
    border-radius: 4px;
    margin-bottom: 20px;
}

.marker-popup .price-bar .price-indicator {
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    background-color: #4A4A4A;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.marker-popup .price-info,
.marker-popup .property-info,
.marker-popup .projected-costs {
    margin-bottom: 20px;
}

.marker-popup .price-info div,
.marker-popup .property-info div,
.marker-popup .projected-costs div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
}

.marker-popup .price-info div {
    font-weight: bold;
}

.marker-popup .projected-costs h4 {
    margin-top: 0;
    margin-bottom: 12px;
    color: #4A90E2;
}

.marker-popup .projected-costs .total {
    font-weight: bold;
    border-top: 1px solid #E0E0E0;
    padding-top: 8px;
    margin-top: 8px;
}

.marker-popup .info-button {
    width: 100%;
    padding: 10px;
    background-color: #4A90E2;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
}

.marker-popup .info-button:hover {
    background-color: #357ABD;
}

.marker-popup .additional-info {
    margin-top: 16px;
    font-size: 12px;
    color: #666;
    line-height: 1.4;
}

.marker-popup .icon {
    font-style: normal;
    margin-right: 8px;
}